<template>
  <v-card class="elevation-2">
    <v-dialog v-model="userViewDialog" max-width="1000px">
      <UserInfo @closeDialog="closeDialog($event)" />
    </v-dialog>
    <!-- <v-dialog v-model="userAddDialog" max-width="800px">
      <UserForm userFormTitle="Add User" @closeDialog="closeDialog($event)" />
    </v-dialog> -->
    <v-dialog v-model="userEditDialog" max-width="800px">
      <UserForm
        userFormTitle="Update User"
        :isUpdating="true"
        :userData="userData"
        @closeDialog="closeDialog($event)"
      />
    </v-dialog>
    <!-- <v-dialog v-model="userDeleteDialog" max-width="500px">
      <v-card>
        <v-card-title class="main-red-bg white--text">
          <span class="text-h5">Delete User</span>
        </v-card-title>
        <v-divider class="mb-2"></v-divider>
        <v-card-text>
          <span class="text-h6"
            >Are you sure you want to delete
            {{ this.$store.getters["usersModule/getUserData"].firstName }}'s
            ?</span
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            class="ma-2 white--text px-3"
            @click="closeDialog('delete')"
          >
            Cancel
          </v-btn>
          <v-btn
            @click="deleteUser"
            :loading="deletingUser"
            color="warning"
            class="ma-2 white--text px-3"
          >
            <v-icon left dark> mdi-delete </v-icon>
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <!-- <v-dialog v-model="userResetDialog" max-width="500px">
      <v-card>
        <v-card-title class="main-red-bg white--text">
          <span class="text-h5">Reset User's Password</span>
        </v-card-title>
        <v-divider class="mb-2"></v-divider>
        <v-card-text>
          <v-alert border="left" colored-border type="warning" elevation="2">
            Password will be reset into : {{ randomPassword }}
          </v-alert>
          <span
            >Are you sure you want to reset
            {{ this.$store.getters["usersModule/getUserData"].firstName }}'s
            password ?</span
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            class="ma-2 white--text px-3"
            @click="closeDialog('reset')"
          >
            Cancel
          </v-btn>
          <v-btn
            @click="resetUserPassword"
            :loading="resetingPassword"
            color="warning"
            class="ma-2 white--text px-3"
          >
            <v-icon left dark> mdi-key </v-icon>
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <v-card-title>
      <v-row>
        <v-col
          :class="$vuetify.breakpoint.xs ? 'justify-center' : ''"
          class="d-flex align-center"
          cols="12"
          sm="6"
        >
          <h2 class="mx-2">Users Table</h2>
        </v-col>
        <v-col
          :class="$vuetify.breakpoint.xs ? 'justify-center' : ''"
          class="d-flex justify-end"
          cols="12"
          sm="6"
        >
          <v-btn-toggle>
            <v-btn
              color="success"
              class="px-4 remove-toggled-state"
              light
              medium
              :loading="syncing"
              @click="syncUsers()"
            >
              Sync Users
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col class="d-flex align-center" cols="12" sm="6">
          <FilterSelect
            filterLabel="Role"
            :filterOptions="userRoles"
            @change="filterUsersByRole = $event"
          />
        </v-col>
        <v-col class="d-flex align-center" cols="12" sm="6">
          <SearchQuery @search="searchInputQuery = $event" />
        </v-col>
      </v-row>
    </v-card-title>
    <UserList
      :searchInput="searchInputQuery"
      :filterUsersByRole="filterUsersByRole"
      @openDialog="openDialog($event)"
    />
  </v-card>
</template>

<script>
import SearchQuery from "@/components/SearchQuery";
import FilterSelect from "@/components/FilterSelect";
import UserList from "@/components/UserList";
import UserForm from "@/components/UserForm";
import UserInfo from "@/components/UserInfo";
import UserImportDialog from "@/components/UserImportDialog";
import { mapGetters } from "vuex";
export default {
  metaInfo: {
    title: "Users",
  },
  name: "UserIndex",
  components: {
    SearchQuery,
    FilterSelect,
    UserList,
    UserForm,
    UserInfo,
    UserImportDialog
  },
  data() {
    return {
      syncing: false,
      filterUsersByRole: "",
      searchInputQuery: "",
      // userRoles: ["admin", "teacher", "student"],
      userRoles: ["teacher", "student"],
      userViewDialog: false,
      // userAddDialog: false,
      userEditDialog: false,
      // userResetDialog: false,
      // userDeleteDialog: false,
      // deletingUser: false,
      // resetingPassword: false,
      // randomPassword: "",
    };
  },
  computed: {
    ...mapGetters({
      getUserData: "usersModule/getUserData",
    }),
    userData() {
      return this.getUserData;
    },
  },
  methods: {
    async syncUsers() {
      this.syncing = true;
      await this.$store.dispatch("usersModule/syncUsers");
      this.syncing = false;
    },
    async openDialog(dialogObject) {
      if (dialogObject.userId) {
        await this.$store.dispatch(
          "usersModule/getUserById",
          dialogObject.userId
        );
      }
      switch (dialogObject.action) {
        case "view":
          this.userViewDialog = true;
          break;
        // case "add":
        //   this.userAddDialog = true;
        //   break;
        case "edit":
          this.userEditDialog = true;
          break;
        // case "reset":
        //   let random = Math.random().toString(36).substring(2, 10);

        //   this.randomPassword = random;
        //   this.userResetDialog = true;
        //   break;
        // case "delete":
        //   this.userDeleteDialog = true;
        //   break;
      }
    },
    closeDialog(dialog) {
      switch (dialog) {
        case "view":
          this.userViewDialog = false;
          break;
        // case "add":
        //   this.userAddDialog = false;
        //   break;
        case "edit":
          this.userEditDialog = false;
          break;
        // case "reset":
        //   this.randomPassword = "";
        //   this.userResetDialog = false;
        //   break;
        // case "delete":
        //   this.userDeleteDialog = false;
        //   break;
      }
    },
    async resetUserPassword() {
      const userId = this.$store.getters["usersModule/getUserData"].id;
      this.resetingPassword = true;
      const data = {
        id: userId,
        password: this.randomPassword,
      };

      await this.$store.dispatch("usersModule/resetUserById", data);
      this.resetingPassword = false;
      this.closeDialog("reset");
    },
    async deleteUser() {
      const userId = this.$store.getters["usersModule/getUserData"].id;
      this.deletingUser = true;
      await this.$store.dispatch("usersModule/deleteUserById", userId);
      this.deletingUser = false;
      this.closeDialog("delete");
    },
  },
};
</script>
<style scoped>
.remove-toggled-state {
  color: white !important;
}
</style>
