<template>
  <v-dialog v-model="dialog" max-width="900px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
        medium
        class="px-4 remove-toggled-state"
      >
        Import Users
      </v-btn>
    </template>
    <v-form v-model="isImportValid" ref="importUsers">
      <v-card>
        <v-card-title>
          <span class="text-h5">Upload CSV</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" :sm="colSet">
                <v-select
                  v-model="importData.role"
                  label="Role"
                  @change="setUserRole($event)"
                  :items="userRoles"
                  :rules="[validators.required]"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" v-if="isRoleStudent">
                <v-autocomplete
                  v-model="importData.sectionId"
                  label="Section"
                  cache-items
                  :items="sectionsAutocomplete"
                  :rules="[validators.required]"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-if="showDownloadFormat">
              <v-col cols="12">
                <v-alert prominent text type="info" class="pa-6">
                  <v-row align="center" class="px-4">
                    <span
                      >NOTE : sample import file will change if importing
                      student accounts, please download the format after
                      selecting student role.</span
                    >
                    <v-col class="grow black--text">
                      Please use this sample import format, replace sample data
                      and do not remove first row.
                    </v-col>
                    <v-col class="shrink">
                      <v-btn color="error" @click="downloadFileCSV">
                        <v-icon left> mdi-download </v-icon>
                        Download
                      </v-btn>
                      <small class="black--text">CSV Format</small>
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12">
                <v-file-input
                  v-model="importData.uploadFile"
                  accept=".csv"
                  label="File input"
                  :rules="[validators.required]"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            class="ma-2 white--text px-3"
            @click="closeDialog"
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="!isImportValid"
            :loading="importLoading"
            color="success"
            class="ma-2 white--text px-3"
            @click="importUsers"
          >
            <v-icon left dark> mdi-cloud-upload </v-icon>
            Upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import validators from "@/helpers/validationHelper";
import { mapGetters } from "vuex";
export default {
  name: "UserImportDialog",
  data() {
    return {
      dialog: false,
      validators: validators,
      isImportValid: false,
      colSet: 12,
      isRoleStudent: false,
      showDownloadFormat: false,
      isInputFileValid: false,
      importLoading: false,
      userRoles: [
        // { text: "admin", value: "admin" },
        { text: "student", value: "student" },
        { text: "teacher", value: "teacher" },
      ],
      importData: {
        role: "",
        sectionId: "",
        uploadFile: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      getSectionsAutocomplete: "sectionsModule/getSectionsAutocomplete",
    }),
    sectionsAutocomplete: function () {
      return this.getSectionsAutocomplete;
    },
  },
  methods: {
    downloadFileCSV() {
      this.$store.dispatch("usersModule/downloadFileCSV", this.importData.role);
    },
    setUserRole(role) {
      if (role === "student") {
        this.colSet = 6;
        this.isRoleStudent = true;
      } else {
        this.colSet = 12;
        this.isRoleStudent = false;
      }
      this.showDownloadFormat = true;
    },
    async importUsers() {
      if (this.importData.uploadFile.name) {
        this.importLoading = true;
        await this.$store.dispatch("usersModule/importUsers", this.importData);
        this.importLoading = false;
        this.closeDialog();
      } else {
        this.isImportValid = false;
      }
    },
    closeDialog() {
      this.$refs.importUsers.reset();
      this.showDownloadFormat = false;
      this.isRoleStudent = false;
      this.dialog = false;
    },
  },
  async mounted() {
    await this.$store.dispatch("sectionsModule/getSections");
  },
};
</script>
<style scoped>
.remove-toggled-state {
  color: white !important;
}
</style>
