<template>
  <v-card>
    <v-card-title class="main-red-bg white--text">
      <span class="text-h5">{{ userFormTitle }}</span>
    </v-card-title>
    <v-divider class="mb-2"></v-divider>
    <v-card-text>
      <v-form v-model="isUserFormValid" ref="userForm">
        <v-container>
          <v-row>
            <!-- <v-col cols="12" sm="4">
              <v-text-field
                v-model="userFormData.firstName"
                label="First Name"
                :rules="[validators.required]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="userFormData.middleName"
                label="Middle Name"
                hint="(Optional)"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="userFormData.lastName"
                label="Last Name"
                :rules="[validators.required]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="userFormData.username"
                label="Username"
                :rules="[validators.required, isUsernameUnique]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="userFormData.email"
                label="Email"
                :rules="[
                  validators.required,
                  isEmailUnique,
                  validators.validEmail,
                ]"
              >
              </v-text-field>
            </v-col> -->
            <!-- <v-col cols="12" sm="6" v-if="!isUpdating">
              <v-text-field
                v-model="userFormData.password"
                label="Password"
                type="password"
                :rules="[validators.required, validators.validPassword]"
              ></v-text-field>
            </v-col> -->
            <!-- <v-col cols="12" sm="6" v-if="!isUpdating">
              <v-text-field
                v-model="userFormData.confirmPassword"
                label="Confirm Password"
                type="password"
                :rules="[validators.required, passwordsMatch]"
              ></v-text-field>
            </v-col> -->
            <!-- <v-col cols="12">
              <v-text-field
                v-model="userFormData.address"
                label="Address"
                :rules="[validators.required]"
              >
              </v-text-field>
            </v-col> -->
            <!-- <v-col cols="12" sm="6">
              <v-text-field
                v-model="userFormData.contactNo"
                label="Contact Number"
                :rules="[validators.required, validators.validPhone]"
              >
              </v-text-field>
            </v-col> -->
            <!-- <v-col cols="12" sm="6">
              <v-select
                v-model="userFormData.role"
                @change="isRoleStudent($event)"
                label="Role"
                :items="userRoles"
                clearable
                :rules="[validators.required]"
              ></v-select>
            </v-col> -->
          </v-row>
          <v-row v-if="roleStudent">
            <v-col cols="12" sm="6">
              <v-select
                v-model="userFormData.classification"
                label="Classification"
                :items="classifications"
                clearable
                :rules="[validators.required]"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="userFormData.sectionId"
                label="Section"
                cache-items
                clearable
                :items="sectionsAutocomplete"
                :rules="[validators.required]"
              ></v-autocomplete>
            </v-col>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>Academic Year</v-list-item-subtitle>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="userFormData.yearFrom"
                    label="From"
                    type="Number"
                    :rules="[validators.required]"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="userFormData.yearTo"
                    label="To"
                    type="Number"
                    :rules="[validators.required]"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="userFormData.semester"
                    label="Semester"
                    type="Number"
                    :rules="[validators.validSemester]"
                  >
                  </v-text-field>
                </v-col>
              </v-list-item-content>
            </v-list-item>
            <!-- <v-col cols="12" sm="6">
              <v-text-field
                v-model="userFormData.guardian"
                label="Guardian Name"
                :rules="[validators.required]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="userFormData.guardianContactNo"
                label="Guardian Contact Number"
                :rules="[validators.required, validators.validPhone]"
              >
              </v-text-field>
            </v-col> -->
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="error" class="ma-2 white--text px-3" @click="closeDialog">
        Cancel
      </v-btn>
      <v-btn
        :disabled="!isUserFormValid"
        :loading="loadingSubmit"
        @click="submitUserForm"
        color="success"
        class="ma-2 white--text px-3"
      >
        <v-icon left dark> mdi-send </v-icon>
        {{ formActionLabel }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import validators from "@/helpers/validationHelper";
import { mapGetters } from "vuex";
export default {
  name: "UserForm",
  props: {
    userFormTitle: {
      type: String,
      default: "Add User",
    },
    isUpdating: {
      type: Boolean,
      default: false,
    },
    userData: {
      type: Object,
      default: () => ({
        // firstName: "",
        // middleName: "",
        // lastName: "",
        // username: "",
        // email: "",
        // password: "",
        // confirmPassword: "",
        // address: "",
        // contactNo: "",
        role: "",
        // student additional fields
        // guardian: "",
        // guardianContactNo: "",
        yearFrom: "",
        yearTo: "",
        semester: "",
        classification: "",
        sectionId: "",
      }),
    },
  },
  data() {
    return {
      isUserFormValid: false,
      loadingSubmit: false,
      validators: validators,
      userRoles: [
        // { text: "admin", value: "admin" },
        { text: "student", value: "student" },
        { text: "teacher", value: "teacher" },
      ],
      classifications: [
        { text: "Regular", value: 1 },
        { text: "Irregular", value: 0 },
      ],
      roleStudent: false,
      userFormData: {
        firstName: "",
        middleName: "",
        lastName: "",
        username: "",
        email: "",
        // password: "",
        // confirmPassword: "",
        address: "",
        // contactNo: "",
        role: "",
        // student additional fields
        // guardian: "",
        // guardianContactNo: "",
        yearFrom: "",
        yearTo: "",
        semester: "",
        classification: "",
        sectionId: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      getUsernamesTaken: "usersModule/getUsernamesTaken",
      getEmailsTaken: "usersModule/getEmailsTaken",
      getSectionsAutocomplete: "sectionsModule/getSectionsAutocomplete",
    }),
    formActionLabel: function () {
      return this.isUpdating ? "Update" : "Submit";
    },
    sectionsAutocomplete: function () {
      return this.getSectionsAutocomplete;
    },
  },
  methods: {
    isRoleStudent(role) {
      if (role === "student") {
        this.roleStudent = true;
      } else {
        this.roleStudent = false;
      }
    },
    isUsernameUnique(username) {
      if (this.isUpdating && this.userData.username === username) {
        return true;
      }
      return this.getUsernamesTaken.includes(username)
        ? "Username already taken"
        : true;
    },
    isEmailUnique(email) {
      if (this.isUpdating && this.userData.email === email) {
        return true;
      }
      return this.getEmailsTaken.includes(email) ? "Email already used" : true;
    },
    passwordsMatch(password) {
      return this.userFormData.password !== password
        ? "Password did not match"
        : true;
    },
    validateYearTo(year) {
      if (year < this.userFormData.yearFrom) {
        return `Please input year from ${this.userFormData.yearFrom}`;
      }

      return true;
    },
    async submitUserForm() {
      this.loadingSubmit = true;
      const revalidatedForm = this.$refs.userForm.validate();
      if (revalidatedForm) {
        await this.$store.dispatch("usersModule/submitUserForm", {
          user: this.userFormData,
          isUpdating: this.isUpdating,
        });
      }
      this.loadingSubmit = false;
      this.closeDialog();
    },
    closeDialog() {
      this.$refs.userForm.reset();
      this.$store.commit("usersModule/clearUserData");
      this.$emit("closeDialog", this.isUpdating ? "edit" : "add");
    },
  },
  watch: {
    userData: {
      handler(newUserFormData) {
        if (this.isUpdating) {
          if (newUserFormData.role === "student") {
            this.roleStudent = true;
          } else {
            this.roleStudent = false;
          }

          this.userFormData = newUserFormData;
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    await this.$store.dispatch("sectionsModule/getSections");
  },
};
</script>
