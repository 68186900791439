<template>
  <v-card>
    <v-card-title class="main-red-bg white--text">
      <span class="text-h5">User Details</span>
    </v-card-title>
    <v-divider class="mb-2"></v-divider>
    <v-card-text>
      <v-row class="mt-2">
        <v-col cols="12" md="5">
          <v-card elevation="2" shaped tile class="text-center rounded-lg pt-4">
            <v-img
              max-width="150"
              contain
              src="@/assets/profile-picture.jpg"
              class="mx-auto rounded-circle"
            ></v-img>
            <v-card-title class="d-block word-break-unset">
              {{ fullName }}
            </v-card-title>
            <v-card-subtitle>
              {{ userData.role }}
            </v-card-subtitle>
          </v-card>
        </v-col>
        <v-col cols="12" md="7">
          <v-card elevation="2" shaped tile class="rounded-lg">
            <v-list>
              <v-subheader class="font-weight-bold text-lg"
                >Profile Information</v-subheader
              >
              <v-divider class="mb-2"></v-divider>
              <!-- <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Contact No</v-list-item-subtitle>
                  <v-list-item-title class="title">{{
                    userData.contactNo || "---- --- ----"
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item> -->
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Username</v-list-item-subtitle>
                  <v-list-item-title class="title">{{
                    userData.username
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Email</v-list-item-subtitle>
                  <v-list-item-title class="title">{{
                    userData.email
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Address</v-list-item-subtitle>
                  <v-list-item-title class="title">{{
                    userData.address
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <div v-if="userData.role == 'student'">
                <!-- <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>Guardian</v-list-item-subtitle>
                    <v-list-item-title class="title">{{
                      userData.guardian
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>Contact</v-list-item-subtitle>
                    <v-list-item-title class="title">{{
                      userData.guardianContactNo
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item> -->
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      Academic Year - Semester : {{ userData.semester }}
                    </v-list-item-subtitle>
                    <v-list-item-title class="title"
                      >{{ `${userData.yearFrom} - ${userData.yearTo}` }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      Section ({{ classification }})
                    </v-list-item-subtitle>
                    <v-list-item-title class="title"
                      >{{ section }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="error" class="ma-2 white--text px-3" @click="closeDialog">
        Back
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: "UserInfo",
  computed: {
    userData: function () {
      return this.$store.getters["usersModule/getUserData"];
    },
    fullName: function () {
      const middleName = this.userData.middleName || "";
      return (
        this.userData.firstName +
        " " +
        middleName +
        " " +
        this.userData.lastName
      );
    },
    classification: function () {
      return this.userData.classification ? "Regular" : "Irregular";
    },
    section: function () {
      if (this.userData.sectionId) {
        const sections = this.$store.getters["sectionsModule/getSections"];
        const section = sections.find(
          (section) => section.id === this.userData.sectionId
        );
        return section.name + " - Grade level : " + section.gradeLevel;
      }
    },
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog", "view");
    },
  },
};
</script>
<style scoped>
.word-break-unset {
  word-break: unset;
}
</style>
