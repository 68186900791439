<template>
  <div>
  <v-btn :disabled="users.length === 0" color="primary" small class="mx-2">
    <JsonExcel
      :data="users"
      :fields="userFields"
      type="csv"
      name="users.csv"
    >
      Export Users
      <v-icon size="20">mdi-download</v-icon>
    </JsonExcel>
  </v-btn>
  <v-data-table
    :search="searchInput"
    :headers="userHeaders"
    :items="users"
    :loading="usersLoading"
    :footer-props="{
      itemsPerPageOptions: usersPerPage,
    }"
  >
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn-toggle rounded>
        <v-btn
          class="remove-toggled-state"
          small
          color="success"
          @click="openDialog('view', item.id)"
        >
          <v-icon small color="white"> mdi-eye </v-icon>
        </v-btn>
        <v-btn
          class="remove-toggled-state"
          small
          color="primary"
          :disabled="item.role == 'teacher'"
          @click="openDialog('edit', item.id)"
        >
          <v-icon small color="white"> mdi-pencil </v-icon>
        </v-btn>
        <!-- <v-btn
          class="remove-toggled-state"
          small
          color="warning"
          @click="openDialog('reset', item.id)"
        >
          <v-icon small color="white"> mdi-key </v-icon>
        </v-btn> -->
        <!-- <v-btn
          class="remove-toggled-state"
          small
          color="error"
          @click="openDialog('delete', item.id)"
        >
          <v-icon small color="white">mdi-delete</v-icon>
        </v-btn> -->
      </v-btn-toggle>
    </template>
  </v-data-table>
</div>
</template>

<script>
import JsonExcel from "vue-json-excel";

export default {
  name: "UserList",
  components: {
    JsonExcel
  },
  props: {
    searchInput: {
      type: String,
      default: "",
    },
    filterUsersByRole: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      usersPerPage: [10, 15, 25, 50, 100],
      userFields: {
        "First Name": "firstName",
        "Middle Name": "middleName",
        "Last Name": "lastName",
        "Role": "role",
        "Email": "email",
        "Username": "username"
      },
    };
  },
  computed: {
    users: function () {
      return this.$store.state.usersModule.users;
    },
    usersLoading: function () {
      return this.$store.state.usersModule.usersLoading;
    },
    userHeaders: function () {
      return [
        {
          text: "First Name",
          align: "start",
          sortable: true,
          value: "firstName",
        },
        {
          text: "Middle Name",
          align: "start",
          sortable: true,
          value: "middleName",
        },
        {
          text: "Last Name",
          align: "start",
          sortable: true,
          value: "lastName",
        },
        {
          text: "Role",
          align: "center",
          sortable: true,
          value: "role",
          filter: (value) => {
            if (!this.filterUsersByRole) {
              return true;
            }
            return this.filterUsersByRole == value;
          },
        },
        {
          text: "Actions",
          align: "center",
          value: "actions",
        },
      ];
    },
  },
  methods: {
    openDialog(action, userId) {
      this.$emit("openDialog", { action: action, userId: userId });
    },
  },
  async mounted() {
    await this.$store.dispatch("usersModule/getUsers");
  },
};
</script>
<style scoped>
.remove-toggled-state {
  color: white !important;
}
</style>
